<template>
  <v-card :loading="isLoading">
    <v-card-title class="break-word-normal" primary-title>
      <div>
        <v-icon left>
          {{ $vuetify.icons.values.alert }}
        </v-icon>
        {{ $trans("cancel_subscription_dialog_title") }}
      </div>
    </v-card-title>

    <v-card-text>
      <p class="text-subtitle-1 font-weight-medium">
        <span
          >{{ $trans("selected_ids_label") }}:
          {{
            userSubscriptions
              .map((userSubscription) => userSubscription.id)
              .join(", ")
          }}</span
        >
      </p>
      <p class="text-subtitle-1">
        <template v-if="userSubscriptions.length > 1">{{
          $trans("cancel_multiple_subscription_dialog_question")
        }}</template>
        <template v-else>{{
          $trans("cancel_single_subscription_dialog_question")
        }}</template>
      </p>
      <p class="accent_light--text text-subtitle-1">
        {{ $trans("no_way_to_undo_action") }}
      </p>
    </v-card-text>

    <v-card-actions>
      <v-checkbox v-model="cancelNow" dense :disabled="isLoading" hide-details>
        <template #label>
          <span>{{
            $trans("cancel_subscription_immediately_checkbox_title")
          }}</span>
        </template>
      </v-checkbox>
    </v-card-actions>

    <v-card-actions>
      <v-spacer />
      <v-btn color="light" text @click="$emit('cancel', false)">
        {{ $trans("close") }}
      </v-btn>
      <v-btn
        :disabled="isLoading"
        color="accent_light"
        text
        @click="handleCancelSubscriptionAction"
      >
        <span v-if="userSubscriptions.length > 1">{{
          $trans("cancel_multiple_subscription_confirm_button")
        }}</span>
        <span v-else>{{
          $trans("cancel_single_subscription_confirm_button")
        }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import eventActions from "@/views/dashboard/pages/Sales/Events/components/EventList/mixins/eventActions";

export default {
  name: "RemoveEventsDialog",
  mixins: [sharedActions, eventActions],
  data() {
    return {
      isLoading: false,
      cancelNow: false,
      userSubscriptions: null,
    };
  },
  created() {
    this.cancelNow = this.getConfirmDialog.data.cancelNow;
    this.userSubscriptions = this.getConfirmDialog.data.userSubscriptions;
  },
  methods: {
    ...mapActions({
      deleteMultipleBooking: "booking/deleteMultiple",
      cancelUserSubscription: "subscriptions/cancelUserSubscription",
      refreshUserSubscriptionsList:
        "subscriptions/refreshUserSubscriptionsList",
    }),
    handleCancelSubscriptionAction() {
      this.isLoading = true;

      pushEvent("cancelUserSubscription");
      let numFailedRequests = 0;

      const cancelPromises = this.userSubscriptions.map((userSubscription) => {
        return this.cancelUserSubscription({
          id: userSubscription.id,
          cancel_now: this.cancelNow,
        }).catch(() => {
          numFailedRequests++;
        });
      });

      Promise.all(cancelPromises).finally(() => {
        if (numFailedRequests === cancelPromises.length) {
          // All requests failed
          errorNotification("cancel_failed", null, false);
          this.isLoading = false;
        } else {
          successNotification("cancel_succeeded");
          this.isLoading = false;
        }

        this.$emit("close", true);
        this.refreshUserSubscriptionsList();
      });
    },
  },
};
</script>
